<template>
  <div class="mt-1">
    <div class="parent">
      <div class="banner d-flex">
        <a class="btn btn-link btn-sm" style="color: #ffffff" @click="goBack()">
          <svg
            fill="#fff"
            class="material-design-icon__svg"
            width="15"
            height="15"
            viewBox="0 0 24 24"
          >
            <path
              d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
            ></path>
          </svg>
          <span> Go Back</span>
        </a>

        <div
          class="title_name w-100 text-center"
          style="margin-top: 0px; margin-left: 5%"
        >
          <h6 class="mb-0 pt-1">JetX</h6>
        </div>

        <div class="title_name w-100 text-center" style="margin-top: 0px">
          <h6 style="float: right; margin-right: 10px" class="mb-0 p-2">
            <a
              class="header-join-button small-text"
              style="color: #000 !important; margin-top: 9px"
              data-toggle="modal"
              data-target="#accountTopUp"
            >
              Deposit
            </a>
          </h6>
        </div>

        <div
          class="modal fade"
          id="accountTopUp"
          tabindex="-1"
          role="dialog"
          aria-labelledby="accountTopUpLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog" role="document">
            <div
              style="background-color: white; color: black"
              class="modal-content"
            >
              <div class="modal-header">
                <h5 class="modal-title text-dark" id="exampleModalLabel">
                  Deposit
                </h5>
                <span type="button" class="close" data-dismiss="modal"
                  >&times;</span
                >
              </div>
              <div class="modal-body">
                <section class="px-2 mb-3">
                  <div class="form-wrapper1 s-a-1 mb-0">
                    <div class="mb-0">
                      <input
                        style="
                          border: 1px black solid;
                          border-radius: 5px;
                          background-color: white;
                          color: black;
                          width: 100%;
                          height: 30px;
                          text-align: center;
                        "
                        type="number"
                        name="amount"
                        id="deposit"
                        placeholder="10"
                        min="0"
                        class="form-control"
                        value="99"
                        aria-label="Amount (to the nearest dollar)"
                        v-model="amount"
                      />
                    </div>
                    <br />
                    <div class="d-flex justify-content-between mb-2">
                      <div class="depo-btn-wrapper">
                        <button
                          style="
                            background-color: var(--darkBackground);
                            color: var(--white);
                          "
                          class="btn odd-btn px-2"
                          @click="setAmount(20)"
                        >
                          +<span class="deposit_val">20</span>
                        </button>
                      </div>
                      <div class="depo-btn-wrapper">
                        <button
                          style="
                            background-color: var(--darkBackground);
                            color: var(--white);
                          "
                          class="btn odd-btn px-2"
                          @click="setAmount(50)"
                        >
                          +<span class="deposit_val">50</span>
                        </button>
                      </div>
                      <div class="depo-btn-wrapper">
                        <button
                          style="
                            background-color: var(--darkBackground);
                            color: var(--white);
                          "
                          class="btn odd-btn px-2"
                          @click="setAmount(100)"
                        >
                          +<span class="deposit_val">100</span>
                        </button>
                      </div>
                      <div class="depo-btn-wrapper">
                        <button
                          style="
                            background-color: var(--darkBackground);
                            color: var(--white);
                          "
                          class="btn odd-btn px-2"
                          @click="setAmount(500)"
                        >
                          +<span class="deposit_val">500</span>
                        </button>
                      </div>
                    </div>
                    <small
                      style="color: var(--darkBackground)"
                      class="s-a-1-txt mt-3 mb-3"
                      >Minimum Deposit amount: {{ currency }} 10</small
                    >
                    <button
                      style="
                        background-color: var(--darkBackground);
                        color: var(--white);
                      "
                      @click="deposit"
                      id="deposit-profile"
                      v-bind:class="loading"
                      class="copy-betslip-btn1 py-2 form-control"
                    >
                      Deposit
                    </button>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <iframe
        allow="fullscreen"
        v-show="isLoaded"
        class="iframe-container"
        v-bind:src="launchURL"
        allowfullscreen
        webkitallowfullscreen
      ></iframe>
      <div class="banner d-flex">
        <p class="btn btn-link btn-sm" style="color: #ffffff">
          <span> JetX Page</span>
        </p>
      </div>
      <div style="display: none">
        <h1>Play JetX at Choplife and Become a Winner!</h1>
        <p>
          JetX is a new online game from SmartSoft Gaming you should definitely
          try on Choplife right now. Unlike some other games, it has no limit in
          how much you can win, which might appeal to gambling Kenyans. While it
          is risky and luck-based, some strategies and tips might help you win a
          lot with it. Let’s learn more about the game right now.
        </p>

        <h2>Jet X Game Rules</h2>
        <p>
          The rules of the game are rather simple. The gameplay consists of a
          jet plane rising into the sky up until it explodes. With an airplane
          rising, the multiplier is rising as well. It starts from the value of
          1.01x and can grow up to 999,999x.
        </p>

        <p>
          The possibility of the jet exploding is determined every 1/7 of a
          second. Each time, the possibility of explosion is around 1%, and the
          possibility of the plane continuing to rise is around 99%. This means
          that, on average, one round will continue for around 5-6 seconds
          before ending in an explosion.
        </p>

        <p>
          In the JetX bet, you need to make a wager on the multiplier value that
          will be reached in a turn. If the plane reaches the multiplier you’ve
          bet on and does not explode, the pilot will eject and, even though the
          jet might rise higher, you will win the value you’ve bet on. If the
          plane doesn’t reach the multiplier you’ve bet on, you lose your bet.
          You can make two bets on two different values, and then the plane will
          have two pilots that will eject at different altitudes.
        </p>

        <p>
          While the Jet X game is simple and has only two outcomes – win or lose
          – there is some depth to it as well. The game return is said to be
          97%, so there is a big chance for you to win if you keep playing the
          game on Choplife.
        </p>

        <h3>Pro Tips to Play JetX at Choplife</h3>
        <p>
          The Choplife JetX gameplay includes a certain social element, namely
          that you can see how many people are playing every turn, how much they
          bet, and how many of them are winning. There is also a Statistics tab
          on the left that shows the amount bet on each multiplier.
        </p>

        <p>
          While the outcome of each turn is random and whether you win or not is
          completely random, certain tips might help you win in the long run:
        </p>

        <p>
          Don’t bet on high multipliers. While it might seem tempting to bet on
          a high multiplier to win a lot at once, in the long run, this strategy
          does not pay out. A steady winning with a small multiplier is much
          more profitable;
        </p>

        <p>
          Select a basic bet amount and stick to it. Start betting with a small
          amount of money so that your losses don’t drain your whole budget
          fast. Think of winning in the long run, so, even if your momentary
          success doesn’t excite you as much, remember that it will ensure your
          gain long-term;
        </p>

        <p>
          Double your bet after each loss. This way with each win you will
          recuperate all the previous losses, and even make some as well.
          However, remember that this approach might drain your overall stack
          faster;
        </p>

        <p>
          Follow the bets of successful players. The game has a social aspect –
          use it to your advantage. If you see that the person wins over and
          over again, try following their betting pattern. You can also try
          making a Jet X bet on what most players are wagering before the turn.
        </p>

        <h3>JetX Bet at Choplife App</h3>
        <p>
          Choplife Casino provides its players with an incredible mobile
          application that allows them to play their favorite casino games and
          place bets on sports anytime and anywhere! Thanks to our application,
          the players immerse themselves in the exciting world of the JetX bet
          Choplife game: control the plane and win anywhere. Install our mobile
          application on your smartphone right now and start flying to win the
          JetX betting game!
        </p>

        <h3>Play Choplife JetX!</h3>
        <p>
          Choplife is the best place you can play JetX in Kenya. There are
          multiple advantages our platform has over the others, here are just
          some of them:
        </p>

        <p>
          Choplife is a trustworthy brand, with millions of players using it
          throughout Kenya. It is also a licensed company by BCLB, meaning that
          experts have confirmed its unbiased trustworthy nature;
        </p>

        <p>
          An account on Choplife is easy to start and manage, the whole
          registration process takes only a few minutes, and you only need to
          make a small deposit to start playing right away. And you don’t have
          to wait to withdraw your winnings! Furthermore, there are multiple
          ways for you to do so, from using a simple bank card to doing it
          through an e-wallet, or even with cryptocurrency;
        </p>

        <p>
          If you get tired from playing JetX, there are dozens of other casino
          games available to you: card games as Aviator, Comet Crash casino
          slots, various types of roulette games and many others. You can also
          bet on any sporting event you’re interested in, or play in a casino
          live; There are a lot of ways to earn more money. Promotional offers
          change frequently and help newcomers to get into new games.
        </p>

        <p>
          With a Choplife mobile app, you can play your favorite games and can
          bet from your smartphone wherever you are;
        </p>

        <p>
          Choplife responsive Customer Service is online 24/7 on 0716797777, so
          you can be sure that any issue you might have will be resolved in the
          shortest time possible.
        </p>

        <h3>Choplife JetX FAQ</h3>
        <p>Is JetX legit?</p>
        <p>
          Yes, JetX is secure from any external influences and completely
          randomized.
        </p>

        <p>How is the JetX win calculated?</p>
        <p>
          Your win is your winning bet multiplied by a multiplier you’ve bet on.
        </p>

        <p>How to get my win in JetX?</p>
        <p>Your wins are transferred directly to your Choplife account.</p>

        <p>How JetX Jackpots are played?</p>
        <p>
          The jackpots are awarded randomly if your bet is higher than $1 and
          your multiplier is higher than 1.5x.
        </p>

        <p>How to play JetX</p>
        <p>
          The rules of the game in JetX Choplife are as simple as possible. The
          higher the flight of the aircraft, the greater the win rate. Just stop
          the JetX casino simulator before the explosion and win by a
          coefficient shown on the screen (it grows all the time). If the plane
          explodes, you have to bet on Jet X again.
        </p>

        <p>What is the JetX game?</p>
        <p>
          JetX casino game is an online air crash simulator that is a novelty in
          the world of online gambling. This simulator is distinguished by the
          simplicity of the game rules, addictive gameplay, a new level of
          excitement, different from the usual slots, and the ability to win a
          big money prize during one JetX gambling session in a few seconds. Jet
          X casino players aren’t limited in their actions – only patience and
          determination can bring high winnings.
        </p>

        <p>How do you win a JetX game?</p>
        <p>
          To bet JetX and win, you need to set the bet size and stick to it,
          keep an eye on the flight altitude of the aircraft, which has no limit
          on the increase, and also stop the flight as soon as it seems that
          there will be an explosion.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import prag from "@/services/prag";
import stp from "@/services/stp";
import elbet from "@/services/elbet";
import smart from "@/services/smart";
import jetsafi from "@/services/jetsafi";
import spribe from "@/services/spribe";
import booming from "@/services/booming";
import shacksevo from "@/services/shacksevo";

import walletserve from "@/services/walletserve";

export default {
  name: "jetx",
  components: {},
  data: function () {
    return {
      currency: process.env.VUE_APP_CURRENCY,
      loading: "",
      gameName: "",
      gameID: "",
      icon: "",
      isLoaded: false,
      launchURL: "",
      launchParameters: {},
      launchType: 1,
      isDemo: 0,

      amount: "",
    };
  },
  mounted() {
    this.$store.dispatch("setCurrentPage", "casinogames");
    this.reloadProfile();
  },
  computed: {
    app: function () {
      return this.$store.state.app;
    },

    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        // react to route changes...
        this.gameName = to.params.gameName;
        this.gameID = to.params.gameID;
        this.launchParameters = to.params.launchParameters;
        this.launchType = to.params.launchType;
        this.isDemo = to.params.IsDemo;

        let payload = {
          provider_id: to.params.providerID,
          game_name: to.params.gameName,
          game_id: to.params.gameID,
          is_demo: to.params.isDemo,
        };

        this.getLaunchUrl(payload);
      },
    },
  },
  methods: {
    setAmount: function (amount) {
      this.amount = parseFloat(amount).toFixed(2);
    },

    deposit: function () {
      this.reset();
      var p = this.getAuth();
      if (!p) {
        this.$toast.open({
          message: "Please login to proceed",
          type: "error",
          position: "top",
        });
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.amount < 10) {
        this.$toast.open({
          message: `Enter amount atleast 10 ${this.currency} or above`,
          type: "error",
          position: "top",
        });
        return;
      }

      var vm = this;
      var path = "/deposit/initiate";

      var data = {
        amount: parseInt(this.amount),
      };

      // vm.loading = "loading";
      console.log(this.getAuth());

      walletserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data.data;
          console.log(msg);
          // vm.setSuccess("Success", msg);
          this.$toast.open({
            message: "Success",
            type: "success",
            position: "top",
          });
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.data.error_code) === 401 ||
              parseInt(err.response.data.error_code) === 400 ||
              parseInt(err.response.data.error_code) === 428
            ) {
              this.$toast.open({
                message: "Your session on this device has expired",
                type: "error",
                position: "top",
              });

              vm.logout();
              return;
            } else {
              this.$toast.open({
                message: err.response.data.error_message,
                type: "error",
                position: "top",
              });

              // console.log(JSON.stringify(err.response.data.message));
            }
          } else if (err.request) {
            this.$toast.open({
              message: `${this.$t("checkYourNetwork")}`,
              type: "error",
              position: "top",
            });
            // console.log(JSON.stringify(err.request));
          } else {
            // vm.setError(
            //   `${this.$t("networkError")}`,
            //   `${this.$t("checkYourNetwork")}`
            // );
            console.log(JSON.stringify(err));
          }
        });
    },

    getLaunchUrl(data) {
      console.log("Launch " + JSON.stringify(data, undefined, 2));

      if (!this.profile) {
        this.$toast.open({
          message: "Please login to proceed",
          type: "error",
          position: "top",
        });
        this.setValue("placeBet", 3);
        this.$router.push({ name: "login", params: {} });
        return;
      }

      var providerId = data.provider_id;
      var gameId = data.game_id;
      var gameName = data.game_name;
      var accountID = parseInt(process.env.VUE_APP_CASINO_ACCOUNT_ID);

      var device_type = this.isMobile() ? "mobile" : "desktop";

      let payload = {};

      // Determine which endpoint and payload to use based on provider ID
      if (providerId === 1) {
        payload = {
          account_id: accountID,
          game_id: String(gameId),
          game_name: gameName,
          lobby_url: window.location.origin + "/casino",
          return_url: window.location.origin + "/casino",
          providerId: providerId,
        };
      } else if (providerId === 3) {
        payload = {
          account_id: parseInt(accountID),
          game_id: parseInt(gameId),
          game_name: gameName,
          device_type: String(device_type),
          lobby_url: window.location.origin + "/casino",
          return_url: window.location.origin + "/casino",
          providerId: providerId,
        };
      } else if (providerId === 5) {
        payload = {
          account_id: accountID,
          device_type: String(device_type),
          game_id: String(gameId),
          game_name: gameName,
          return_url: window.location.origin + "/casino",
          providerId: providerId,
        };
      } else if (providerId === 4) {
        payload = {
          account_id: accountID,
          device_type: String(device_type),
          game_id: String(gameId),
          game_name: gameName,
          lobby_url: window.location.origin + "/casino",
          return_url: window.location.origin + "/casino",
          providerId: providerId,
        };
      } else if (providerId === 6) {
        payload = {
          account_id: accountID,
          device_type: String(device_type),
          game_id: String(gameId),
          game_name: gameName,
          lobby_url: window.location.origin + "/casino",
          return_url: window.location.origin + "/casino",
          providerId: providerId,
        };
      } else if (providerId === 7) {
        payload = {
          account_id: accountID,
          device_type: String(device_type),
          game_id: String(gameId),
          game_name: gameName,
          lobby_url: window.location.origin + "/casino",
          return_url: window.location.origin + "/casino",
          providerId: providerId,
        };
      } else if (providerId === 8) {
        payload = {
          account_id: accountID,
          demo: 0,
          device: String(`Device ${device_type}`),
          game_id: String(gameId),
          game_name: gameName,
          lobby_url: window.location.origin + "/casino",
          return_url: window.location.origin + "/casino",
          providerId: providerId,
        };
      } else if (providerId === 9) {
        payload = {
          account_id: accountID,
          demo: 0,
          device: String(`Device ${device_type}`),
          game_id: String(gameId),
          game_name: gameName,
          return_url: window.location.origin + "/casino",
          lobby_url: window.location.origin + "/casino",
          providerId: providerId,
        };
      } else {
        console.error("Invalid providerId:", providerId);
        return; // Exit the function if providerId is invalid
      }

      console.log(
        "provider " +
          providerId +
          " | payload " +
          JSON.stringify(payload, undefined, 2)
      );

      // Make API request with appropriate endpoint and payload
      return this.launchGame(payload); // Return the launch URL
    },

    launchGame: function (payload) {
      var vm = this;
      var providerId = payload.providerId;

      var service;

      switch (providerId) {
        case 1:
          service = prag;
          break;
        case 3:
          service = stp;
          break;
        case 4:
          service = elbet;
          break;
        case 5:
          service = smart;
          break;

        case 6:
          service = jetsafi;
          break;

        case 7:
          service = spribe;
          break;

        case 8:
          service = booming;
          break;

        case 9:
          service = shacksevo;
          break;

        default:
          console.error("Invalid providerId:", providerId);
          return; // Exit the function if providerId is invalid
      }

      service
        .post("/launch/url", payload, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((response) => {
          vm.launchURL = response.data.game_url;
          console.log("LaunchURL", vm.launchURL);

          if (vm.launchType === "3") {
            window.open(this.launchURL);
          }

          console.log("LaunchUrl", vm.launchURL);
          this.isLoaded = true; // Set isLoaded to true after getting the launch URL
          return vm.launchURL;
        })
        .catch((error) => {
          console.error("Error launching game:", error);
        });
    },

    goToCasino: function () {
      this.$router.push({ name: "casino", params: {} });
    },

    showDeposit: function () {
      document.getElementById("deposit-init").click();
    },
  },
  filters: {
    amount: function (val) {
      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },
  },
};
</script>

<style scoped>
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, 50px);
}

.modal-dialog {
  position: fixed !important;
  bottom: 0 !important;
  left: 0% !important;
  right: 0% !important;
  margin-bottom: 0 !important;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--gray-white);
}

.list-active {
  background-color: #f08b05;
}

.title_name {
  color: #fff;
}

.parent {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.parent .banner {
  width: 100%;
  /* height: 48px; */
}

.parent iframe {
  background: #000;
  border: none;
  flex-grow: 1;
}

h4 {
  position: relative;
  top: 0.6em;
  right: 1em;
}
</style>
